import CardContainer from "./components/CardContainer";


function App() {

  return (
    <div className="main">
      <CardContainer/>
    </div>
  );
}

export default App;
