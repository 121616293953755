import {useState, useEffect} from 'react'
import '../css/card_container.css'

import restApiHost from '..';


function CardContainer() {

    
    const [countryName, setCountryName] = useState(null);
    const [casinoCards, setCasinoCards] = useState(null);
    

    useEffect(() => {

        async function fetchCards () {
            const response = await fetch(restApiHost + '/api/get_games/' + countryName + '/');
            const data = await response.json();
            const cards = data.data;
            setCasinoCards(cards);
        };

        async function fetchCountryName() {
            const response = await fetch('https://zaymi-online24.store/geo/get_country_name/');
            const data = await response.json();
            const country = data.country_name.toLowerCase();
            setCountryName(country);
        }
        
        if (countryName === null && casinoCards === null) {
            fetchCountryName();
        }

        if (casinoCards === null && countryName !== null){
            fetchCards();
        }
        
        

    }, [casinoCards, countryName])


    const redirectToGame = (link) => {
        window.open(link, '_self');
    };


    const renderCards = () => {
        const elements = [];
        let cards = null;
        cards = casinoCards;
    
            
        for (let i = 0; i < cards.length; i += 3) {
            
            elements.push(
                <div key={`big-card-${cards[i].id}`} onClick={() => redirectToGame(cards[i].link)} className="big-card" style={{backgroundImage: `url(${cards[i].image})`}}>
                    <div className='play-button-text-area'>
                        <div className='play-button-text'>{cards[i].deposit}</div>
                    </div>
                    <div className='play-button-big-card'> 
                        <div className='play-button-text-big-card'>ИГРАТЬ</div>
                    </div>
                </div>
            );

            if (i + 1 < cards.length || i + 2 < cards.length) {
                elements.push(
                    <div key={`little-cards-${i}`} className="little-cards-container">
                        {i + 1 < cards.length && (
                            <div className="little-card" onClick={() => redirectToGame(cards[i + 1].link)} style={{backgroundImage: `url(${cards[i + 1].image})`}}>
                                <div className='play-button-text-area'>
                                    <div className='play-button-text'>{cards[i + 1].deposit}</div>
                                </div>
                                <div className='play-button-big-card' onClick={() => redirectToGame(cards[i + 1].link)}> 
                                    <div className='play-button-text-big-card'>ИГРАТЬ</div>
                                </div>
                            </div>
                        )}
                        {i + 2 < cards.length && (
                            <div className="little-card" onClick={() => redirectToGame(cards[i + 2].link)} style={{backgroundImage: `url(${cards[i + 2].image})`
                            }}>
                                <div className='play-button-text-area'>
                                    <div className='play-button-text'>{cards[i + 2].deposit}</div>
                                </div>
                            <div className='play-button-big-card' onClick={() => redirectToGame(cards[i + 2].link)}> 
                                <div className='play-button-text-big-card'>ИГРАТЬ</div>

                            </div>
                            </div>
                        )}
                    </div>
                );
            }
        }

        return elements;
    };

    return <div className="card-container">{casinoCards && (renderCards())}<div className='break-line'></div></div>;    
    
};

export default CardContainer;